<!--<div class="row">
  <div class="col-lg-12">
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTerm.value)" [value]="searchTermValue" #searchTerm>
      <span class="input-group-btn" (click)="search(searchTerm.value)">
        <button class="btn btn-default" type="submit" (click)="search(searchTerm.value)">
          <i class="fa fa-search"></i>
        </button>
      </span>
    </div>
  </div>
</div>-->

<tag-input [(ngModel)]='tags' theme="minimal" secondaryPlaceholder="Add search term(s)" placeholder="Add search term(s)"
           (onRemove)="itemUpdated()"
           (onAdd)="itemUpdated()">
</tag-input>

<div style="margin-top: 10px;">
  <div class="radio-group">
      <input class="form-check-input" type="radio" name="searchAll" id="allKeywords" [(ngModel)]="allKeywords" (change)="itemUpdated()" [value]="true">
      <label class="form-check-label" for="allKeywords">
        Search All Keywords
      </label>


      <input class="form-check-input" type="radio" name="searchAll" id="anyKeyword" [(ngModel)]="allKeywords" (change)="itemUpdated()" [value]="false">
      <label class="form-check-label" for="anyKeyword">
        Search Any of the Keywords
      </label>
  </div>
</div>

<div *ngIf="loading" class="row" style="text-align:center;">
  <img src="../../assets/images/loader.gif" />
</div>

<div *ngIf="searchItems == null && !loading && tags != null && !spellingCorrection" style="margin-top:20px;">

  <div>Your search did not return any results. Some suggestions:</div>
  <br />
  <ul>
    <li>Double check your spelling.</li>
    <li>Try entering different keywords.</li>
    <li>Try using more general keywords.</li>
    <li>Try fewer keywords.</li>
  </ul>
  <!--<br />-->
  <div style="padding-top:7px;">Example, if you're looking for Family and Medical Leave Act materials, try searching for "FMLA"</div>

</div>

<div *ngIf="searchItems == null && !loading && tags && tags.length && spellingCorrection" style="margin-top:20px;">
  <h2>No content found to match your criteria. Did you mean <a [routerLink]="['/search/'+ spellingCorrection]" style="cursor:pointer;">{{spellingCorrection}}</a> </h2>
</div>

<div *ngIf="!loading && !tags" style="margin-top:20px;">
  <h2>Please add search term(s) to try another search.</h2>
</div>

<div *ngIf="searchItems != null && !loading" style="margin-top:20px;" class="row">

  <div class="col-lg-3" style="margin-bottom:20px;">
    <a class="clear-all" (click)="clearAll()">Clear all filters</a>
    <div class="outer-filter-panel" #filters>
      <!--<div class="filter-panel" data-toggle="collapse" data-target="#date">
      Date
    </div>
    <div id="date" class="collapse in" style="overflow-y:auto; max-height:186px;">
      <table class="table table-hover">

        <tr *ngFor="let item of searchItems.DateRanges">
          <td (click)="filterDate(item)" [ngClass]="(item.DateRangeDescription == this.selectedDateRange || (this.selectedDateRange == '' && item.DateRangeDescription == 'All')) ? 'active' : ''" style="cursor:pointer;">
            {{item.DateRangeDescription}}<span style="float:right;">{{item.Count}}</span>

          </td>
        </tr>
      </table>
    </div>-->
      <div class="filter-panel  body-content-label">
        Languages<span data-toggle="collapse" (click)="languageCollapsed = !languageCollapsed" data-target="#language" style="float: right;cursor: pointer;">{{ languageCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="language" class="collapse in">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of searchItems.Languages">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterLanguage(item.Language, $event.target.checked)" [checked]="item.Checked">{{item.Language}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="filter-panel  body-content-label">
        States<span data-toggle="collapse" (click)="stateCollapsed = !stateCollapsed" data-target="#state" style="float: right;cursor: pointer;">{{ stateCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="state" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of searchItems.Jurisdictions">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterJurisdiction(item.JurisdictionId, $event.target.checked)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="filter-panel  body-content-label">
        Categories<span data-toggle="collapse" (click)="categoryCollapsed = !categoryCollapsed" data-target="#category" style="float: right;cursor: pointer;">{{ categoryCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="category" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of searchItems.Categories">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterCategory(item.CategoryId, $event.target.checked)" [checked]="item.Checked">{{item.CategoryName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="filter-panel  body-content-label">
        Topics<span data-toggle="collapse" (click)="topicCollapsed = !topicCollapsed" data-target="#topic" style="float: right;cursor: pointer;">{{ topicCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of searchItems.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
        </tbody>
        </table>
      </div>
      <div class="filter-panel  body-content-label">
        Doc Types<span data-toggle="collapse" (click)="docCollapsed = !docCollapsed" data-target="#doc" style="float: right;cursor: pointer;">{{ docCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of searchItems.InformationTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterDocument(item.InformationTypeId, $event.target.checked)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-9" >
    <div class="title-text">{{searchItems.Information.length}} results</div> 
    <div class="white-well" [ngStyle]="{'height.px': filtersHeight || filters.offsetHeight}">
      <div *ngFor="let item of searchItems.Information">

        <div class="title-text">
          <a *ngIf="item.InformationTypeId == 16" (click)="select(item, true)" class="link">{{item.Title}} - {{item.JurisdictionName}}</a>
          <a *ngIf="item.InformationTypeId == 29 && !hasBenefits" (click)="openModal(modal)" class="link">
              <div innerHTML=" {{item.Title | highlightText : tags}}"></div>
          </a>
          <a *ngIf="item.InformationTypeId != 16 && !(item.InformationTypeId == 29 && !hasBenefits)" (click)="select(item, false)" class="link">
            <div innerHTML=" {{item.Title | highlightText : tags}}"></div>
          </a>
       </div>
        <div *ngIf="item.InformationTypeId == 1" style="font-weight:bold; font-size:9pt;  font-family: 'Open Sans', sans-serif !important;">{{item.PubDate  | date: 'EEEE, MMMM d, y'}}</div>
        <div style="font-size:9pt;  font-family: 'Open Sans', sans-serif !important;">{{item.CategoryDesc}}/{{item.TopicDesc}}/{{item.InformationTypeName}}</div><br />
        <div class="body-content">
          <div innerHTML="{{ item.Teaser | highlightText : tags}}"></div>

        </div>
        <hr />

      </div>

    </div>
  </div>

</div>

<!--modal-->
<ng-template cssClass="modal-xl" #modal>

  <div *ngIf="!hasBenefits && !isLimitedAccess">
    <div class="modal-header modal-header-hr">
      Limited Access
    </div>
    <div class="modal-body">
      <div class="body-content">
        <div>
          <p class="header">Full access to HR Hero’s Benefits Books is reserved for add-on subscribers.</p>
          <p>
            <a href="https://landing.blr.com/hr-hero-benefits-upgrade" target="_blank">Request access now</a> to learn about subscription options – a solutions
            expert will reach out ASAP!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLimitedAccess">
    <div class="modal-header modal-header-hr">
      Limited Access
    </div>
    <div class="modal-body">
      <div class="body-content">
        <div>
          <p class="header">Books and Newsletters reserved for HR Hero</p>
          <p>
            Gain access to a complete set of benefits compliance content, tools, and expert services.  Topics include COBRA, HIPAA, pension plans, 401(k),
            health insurance, flex plans and more.  Activation will provide 9 online benefits e-books – over $4,100 in value! <a href="https://landing.blr.com/hr-hero-benefits-upgrade" target="_blank">Subscribe now</a> and take
            advantage of extraordinary savings on the information you need to efficiently manage your benefits
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
  </div>
</ng-template>


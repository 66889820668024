import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { enableDebugTools } from '@angular/platform-browser';
import { Event } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

//services
import { MembershipDataService } from '../app/shared/services/membership.data.service'

//types
import { UserProfile } from '../app/shared/types/user-profile.type'
import { hrHeroConfig } from '../environments/environment'

import  'jquery';

import { HrHeroService } from './shared/services/hr-hero.service';
import { Account } from './shared/types/account.type';


declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  title = 'app';
  currentRoute: string;
  currentSubRoute: string;
  searchTerm: string = null;
  //suggestions: Observable<string[]>;
  typeaheadLoading: boolean;
  isAuthenticated: boolean = false;
  userName: string = null;
  userProfile: UserProfile = new UserProfile;
  headerVisible: boolean = false;

  toolsTab: string = "true";
  hotlineTab: string = "true";
  slcbTab: string = "true";
  jdmTab: string = "true";
  flsaTab: string = "true";
  ehbTab: string = "true";
  trainingTab: string = "true";
  resourcesTab: string = "true";
  allResourcesTab: string = "true";
  calculatorsTab: string = "true";
  referenceTab: string = "true";

  topNavBar: string = "true";
  headerLogo: string;
  footerLogo: string;
  footerAddress: string;
  footerPhone: string;
  hrHeroLink: string = ""; //href automatically assumes origin as prefix
  trainingTodayUrl: string = hrHeroConfig.trainingTodayUrl;

  ehsLink: string = "http://" + hrHeroConfig.origin + "/ehs/";
  footerPhoneDefault: string;
  footerImageDefault: string;
  footerAddressDefault: string;
  headerImageDefault: string;
  headerImage: string;
  footerImage: string;
  willisBranded: boolean = false;
  snBranded: boolean = false;

  dataAvailable: boolean = false;

  inquiryTimeout: number;

  customWidgetsVisible: boolean = false;
  userManagementVisible: boolean = false;
  customSettingsVisible: boolean=false;

  warningTimerSubscribe:Subscription;
  timedOutTimerSubscribe:Subscription;

  readonly warningTime: number=600;//10 min in seconds
  warningTimeRemaining:number;
  showNotificationBanner:boolean = false;
  userLoggedIn:boolean = false;


  @ViewChild('mobileClick') mobileClick: ElementRef;
  @ViewChild('appClick') appClick: ElementRef;

  constructor(private _router: Router, private _mbService: MembershipDataService) {
      this._router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            if(event.url.indexOf("login") > -1)
            {
              this.willisBranded = event.url.indexOf("wtw") > -1;
              this.snBranded = event.url.indexOf("snhr") > -1;
            }
            ga('set', 'page', event.url);
            ga('send', 'pageview');
            if(hrHeroConfig.usesInquiry){
              this.acquireAndSendInquiryInformation();
            }
            if (this.isRouteProtected()) {
              this._mbService.verifyUserProfile();
            }
          }
    });

    //this.suggestions = Observable.create((observer: any) => {
    //    // Runs on every search
    //    observer.next(this.searchTerm);
    //  }).mergeMap((token: string) => this._heroService.getSuggestions(this.searchTerm));
    $('.sitepreloader').css('display', 'none');
  }

  ngOnInit() {

    
    this._mbService.getUserLoggedIn().subscribe(userLoggedIn => {
      this.userLoggedIn = userLoggedIn;
      if (userLoggedIn) {
        if(this.warningTimerSubscribe) 
        {
          this.warningTimerSubscribe.unsubscribe();
        }
    
        if(this.timedOutTimerSubscribe)
        {
          this.timedOutTimerSubscribe.unsubscribe();
        } 
    
        const tokenExpiry = this._mbService.user.TokenExpiry*60000;//millisconds
        const warning = (this.warningTime*1000);//milliseconds      
        const warningTimer = timer(Math.abs(tokenExpiry-warning)); 
        //const warningTimer = timer(60000);        
       this.warningTimerSubscribe = warningTimer.subscribe(n => this.showWarningTimeout());        
      }
    })
  }

  ngOnDestroy(): void {
    if(this.warningTimerSubscribe) 
        {
          this.warningTimerSubscribe.unsubscribe();
        }
    
        if(this.timedOutTimerSubscribe)
        {
          this.timedOutTimerSubscribe.unsubscribe();
        } 
  }
  toggleToolHeader(visible) {
    this.headerVisible = !visible;
  }

  closeMobileDropDown() {
    this.mobileClick.nativeElement.click()
  }
  isRouteProtected() {
    let currentUrl = this._router.url;
    return currentUrl.indexOf("/account-settings") > -1
      || currentUrl.indexOf("/user-management") > -1
      || currentUrl.indexOf("/preferences") > -1
      || currentUrl.indexOf("/custom-settings") > -1
      || currentUrl.indexOf("/edit-widget") > -1
      || currentUrl.indexOf("/tools") > -1
      || currentUrl.indexOf("/job-description-manager") > -1
      || currentUrl.indexOf("/state-law-chart-builder") > -1
      || currentUrl.indexOf("/salary-finder") > -1
      || currentUrl.indexOf("/training") > -1
      || currentUrl.indexOf("/resources") > -1
      || currentUrl.indexOf("/news") > -1
      || currentUrl.indexOf("/podcasts") > -1
      || currentUrl.indexOf("/compliance-dates") > -1
      || currentUrl.indexOf("/resources") > -1
      || currentUrl.indexOf("/checklists-forms") > -1
      || currentUrl.indexOf("/calculators") > -1
      || currentUrl.indexOf("/ReferenceGuides") > -1
      || currentUrl.indexOf("/Book") > -1
      || currentUrl.indexOf("/federal-state-topic-analysis") > -1
      || currentUrl.indexOf("/analysis") > -1
      || currentUrl.indexOf("/myBookmarks") > -1
      || currentUrl.indexOf("/search") > -1
      || currentUrl.indexOf("/dashboard") > -1
      || currentUrl.indexOf("/hotline") > -1
      || currentUrl.indexOf("/hidden-reindex") > -1
      || currentUrl.indexOf("/audio-presentations") > -1
  }

  ngDoCheck() {

    let currentUrl = this._router.url;

    if (currentUrl.indexOf("/unauthorized") > -1) {
      this.currentRoute = "unauthorized";
    }
    else if (currentUrl.indexOf("/account-settings") > -1) {
      this.currentRoute = "settings";
      this.currentSubRoute = "account-settings";
    } else if (currentUrl.indexOf("/user-management") > -1) {
      this.currentRoute = "settings";
      this.currentSubRoute = "user-management";
    } else if (currentUrl.indexOf("/preferences") > -1) {
      this.currentRoute = "settings";
      this.currentSubRoute = "preferences";
    } else if (currentUrl.indexOf("/custom-settings") > -1) {
      this.currentRoute = "settings";
      this.currentSubRoute = "custom-settings";
    } else if (currentUrl.indexOf("/edit-widget") > -1) {
      this.currentRoute = "settings";
      this.currentSubRoute = "/edit-widget";
    } else if (currentUrl.indexOf("/custom-site-settings") > -1) {
        this.currentRoute = "custom-site-settings";
        this.currentSubRoute = "custom-site-settings";
    }

    if (currentUrl.indexOf("/tools") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "";
    } else if (currentUrl.indexOf("/job-description-manager") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "external";
    } else if (currentUrl.indexOf("/state-law-chart-builder") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "external";
    } else if (currentUrl.indexOf("/salary-finder") > -1) {
      this.currentRoute = "tools";
      this.currentSubRoute = "external";
    }
    else if (currentUrl.indexOf("/training") > -1) {
      if (currentUrl.indexOf("/new") > -1) {
        this.currentSubRoute = "new";
      } else if (currentUrl.indexOf("/all") > -1) {
        this.currentSubRoute = "all";
      } else if (currentUrl.indexOf("/trainers-toolbox") > -1) {
        this.currentSubRoute = "trainers-toolbox";
      } else {
        this.currentSubRoute = "all";
      }

      this.currentRoute = "training";

    } else if (currentUrl.indexOf("/news") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "news";
    } else if (currentUrl.indexOf("/podcasts") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "podcasts";
    } else if (currentUrl.indexOf("/compliance-dates") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "compliance-dates";
    } else if (currentUrl.indexOf("/resources") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "resources";
    } else if (currentUrl.indexOf("/calculators") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "calculators";
    } else if (currentUrl.indexOf("/ReferenceGuides") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "ReferenceGuides";
    } else if (currentUrl.indexOf("/Book") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "ReferenceGuides";
    } else if (currentUrl.indexOf("/federal-state-topic-analysis") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "federal-state-topic-analysis";
    } else if (currentUrl.indexOf("/analysis") > -1) {
      this.currentRoute = "resources";
      this.currentSubRoute = "federal-state-topic-analysis";
    } else if (currentUrl.indexOf("/myBookmarks") > -1) {
      this.currentRoute = "myBookmarks";
    } else if (currentUrl.indexOf("/search") > -1) {
      this.currentRoute = "search";
    } else if (currentUrl.indexOf("/dashboard") > -1) {
      this.currentRoute = "dashboard";
    } else if (currentUrl.indexOf("/login") > -1) {
      this.currentRoute = "login";
    } else if (currentUrl.indexOf("/sso-login") > -1) {
      this.currentRoute = "sso-login";
    } else if (currentUrl.indexOf("/pending-login") > -1) {
      this.currentRoute = "pending-login";
    } else if (currentUrl.indexOf("/wtw-login") > -1) {
      this.currentRoute = "wtw-login";
    } else if (currentUrl.indexOf("/snhr-login") > -1) {
      this.currentRoute = "snhr-login";
    } else if (currentUrl.indexOf("/hotline") > -1) {
      this.currentRoute = "hotline";
    } else if (currentUrl.indexOf("/hidden-reindex") > -1) {
      this.currentRoute = "hidden-reindex";
    } else if (currentUrl.indexOf("/error") > -1) {
      this.currentRoute = "error";
    } else if (currentUrl.indexOf("/audio-presentations") > -1) {
      this.currentRoute = "audio-presentations";
    }
    
    if (this._mbService.user != null) {
      this.userName = this._mbService.user.Username;
      window.loggedInUserEmail = this._mbService.user.Email;
    }

    this.customSettingsVisible = this._mbService.userProfileTypeId === 1;
    this.userManagementVisible = this._mbService.AutherizationType==1 && (this._mbService.userProfileTypeId === 1 || (this._mbService.userProfile.AdminPreferences != null && this._mbService.getVisibility('User Management')));
    this.customWidgetsVisible = this._mbService.userProfileTypeId === 1 || (this._mbService.userProfile.AdminPreferences != null && this._mbService.getVisibility('Custom Widgets'));


    if (this._mbService.userProfile != null && this._mbService.userProfile.InheritableProperties != null) {
      this.setHeaderAndFooter();
      this.toolsTab = this._mbService.getSettingValue('Tools');
      this.hotlineTab = this._mbService.getSettingValue('Hotline');
      this.trainingTab = this._mbService.getSettingValue('Training');
      this.resourcesTab = this._mbService.getSettingValue('Resources');

      this.allResourcesTab = this._mbService.getSettingValue('ChecklistsForms');
      this.calculatorsTab = this._mbService.getSettingValue('Calculators');

      this.topNavBar = this._mbService.getSettingValue('TopNavBar');

      this.headerLogo = this._mbService.getSettingValue('HeaderLogo').replace("//","\\");

      //this is temp until profiles are cleaned...
      //if (this._mbService.getSettingValue('FooterLogo') == "../assets/images/HRHero-Logo.png") {
      //  this.footerLogo = "../assets/images/BLR_footerLogo.png";
      //} else {
      //  this.footerLogo = this._mbService.getSettingValue('FooterLogo').replace("//", "\\");
      //}
      //if(this._mbService.userProfile.InheritableProperties.FooterAddress != null){
      //  this.footerAddress = this._mbService.getSettingValue('FooterAddress');
      //  if(this.footerAddress === "true"){
      //    this.footerAddress = "100 Winners Circle, Suite 300, Brentwood, TN 37027";
      //  }else if(this.footerAddress === "false"){
      //    this.footerAddress = "100 Winners Circle, Suite 300, Brentwood, TN 37027";
      //  }
      //}else{
      //  this.footerAddress = "100 Winners Circle, Suite 300, Brentwood, TN 37027";
      //}
      //if(this._mbService.userProfile.InheritableProperties.FooterPhone != null){
      //  this.footerPhone = this._mbService.getSettingValue('FooterPhone');
      //  if(this.footerPhone === "true"){
      //    this.footerPhone = "800-727-5257";
      //  }else if(this.footerPhone === "false"){
      //    this.footerPhone = "800-727-5257";
      //  }
      //}else{
      //  this.footerPhone = "800-727-5257";
      //}

      if (this._mbService.profileSet || this.currentRoute == "sso-login" || this.currentRoute == "login" || this.currentRoute == "pending-login" || this.currentRoute == "wtw-login" || this.currentRoute == "snhr-login") {
        this.dataAvailable = true;
      }
    } else {
      if (this._mbService.profileSet || this.currentRoute == "sso-login" || this.currentRoute == "login" || this.currentRoute == "pending-login" || this.currentRoute == "wtw-login" || this.currentRoute == "snhr-login") {
        this.dataAvailable = true;
      }
    }

    if (this._mbService.userProfile != null && this._mbService.userProfile.InheritableProperties != null) {

      if (this.currentRoute == "tools") {
        if (this._mbService.getSettingValue('Tools') == "false") {
          this.currentRoute = "unauthorized";
          this._router.navigate(["/unauthorized"]);
        }
      }

      if (this.currentRoute == "resources") {
        if (this._mbService.getSettingValue('Resources') == "false") {
          this.currentRoute = "unauthorized";
          this._router.navigate(["/unauthorized"]);
        }
      }

      if (this.currentRoute == "training") {
        if (this._mbService.getSettingValue('Training') == "false") {
          this.currentRoute = "unauthorized";
          this._router.navigate(["/unauthorized"]);
        }
      }

      if (this.currentRoute == "hotline") {
        if (this._mbService.getSettingValue('Hotline') == "false") {
          this.currentRoute = "unauthorized";
          this._router.navigate(["/unauthorized"]);
        }
      }

      if (this.currentSubRoute == "resources") {
        if (this._mbService.getSettingValue('ChecklistsForms') == "false") {
          this.currentRoute = "unauthorized";
          this.currentSubRoute = "";
          this._router.navigate(["/unauthorized"]);
        }
      }

      if (this.currentSubRoute == "calculators") {
        if (this._mbService.getSettingValue('calculators') == "false") {
          this.currentRoute = "unauthorized";
          this.currentSubRoute = "";
          this._router.navigate(["/unauthorized"]);
        }
      }

    }
  }

  search(searchTerm) {
    //this.searchTermValueText = "";
    if (searchTerm)
      this._router.navigate(["/search/" + searchTerm]);
    else
      this._router.navigate(["/search"]);
  }
  //public changeTypeaheadLoading(e: boolean): void {
  //  this.typeaheadLoading = e;
  //}
  //public typeaheadOnSelect(e: TypeaheadMatch): void {
  //  this.search(e.value);
  //}

  navigate(rte, close) {

    if (close == 'close') {
      this.closeMobileDropDown();
    }

    switch (rte) {
      case 'preferences':
        this.customWidgetsVisible = this._mbService.getVisibility('Custom Widgets');
        this.userManagementVisible = this._mbService.getVisibility('User Management') && this._mbService.AutherizationType==1;
        this.customSettingsVisible = true;

        this._router.navigate(["/preferences"]);
        break;
      case 'login':
        location.href = "https://hrhero.blr.com";
        break;
    }
  }

  acquireAndSendInquiryInformation() {
    if (this._mbService.user == null) {
      return; //no logged in user to track
    }

    /*console.log(this._mbService.user.FirstName + " - " + this._mbService.user.LastName + " - " + this._mbService.user.Email + " - " + this._mbService.user.Institution + " - " +
      this._mbService.user.SubscriptionType + " - " + "hero.blr.com/hr" + " - " + "" + " - " + this._mbService.user.LastLoginDate + " - " + this._mbService.user.CustomerId + " - " + this._mbService.user.ParentCustomerId + " - " + this._mbService.user.Username);*/
    this.simplify_inquiry_request(
      this._mbService.user.FirstName, this._mbService.user.LastName, this._mbService.user.Email, this._mbService.user.Institution,
      this._mbService.user.SubscriptionType, hrHeroConfig.origin, "", this._mbService.user.LastLoginDate, this._mbService.user.CustomerId, this._mbService.user.ParentCustomerId, this._mbService.user.Username);
  }

  simplify_inquiry_maintain(id) {
    var xmlhttp; var send;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    } else {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }

    let ths = this;
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        ths.inquiryTimeout = window.setTimeout(function () {
          ths.simplify_inquiry_maintain(xmlhttp.responseText)
        }, 10000);
      }
    }
    xmlhttp.open("POST", ("https://d1v834xrsg9kw8.cloudfront.net/api/observer.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&id=" + id);
  }
  litmosLogin(){
    window.open(this._mbService.litmosLink,"_blank");
  }
  
  simplify_inquiry_request(user_first_name, user_last_name, user_email, user_company, user_subscription_type, request_top_level, request_mid_level, request_low_level, user_id, user_parent_id, user_username) {
    var xmlhttp; var send;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    } else {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }
    let ths = this;
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        window.clearTimeout(ths.inquiryTimeout);
        ths.inquiryTimeout = window.setTimeout(function () {
          ths.simplify_inquiry_maintain(xmlhttp.responseText)
        }, 10000);
      }
    }
    xmlhttp.open("POST", ("https://d1v834xrsg9kw8.cloudfront.net/api/request.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&uf="
      + user_first_name + "&ul=" + user_last_name + "&ue="
      + user_email + "&uc=" + user_company + "&us="
      + user_subscription_type + "&r1=" + request_top_level +
      "&r2=" + request_mid_level + "&r3=" +
      request_low_level + "&uldap=" + user_username +
      "&uccid=" + user_id + "&uccpid=" +
      user_parent_id + "&xu=" + window.location.href + "&xw="
      + screen.width + "&xh=" + screen.height + "&xn="
      + navigator.userAgent + "&xc=" +
      navigator.cookieEnabled + "&xl=" + navigator.language +
      "&xp=" + navigator.platform + "&xx=13");
 }


  logout() {
    this.showNotificationBanner = false;
    if(this.warningTimerSubscribe) 
        {
          this.warningTimerSubscribe.unsubscribe();
        }
    
        if(this.timedOutTimerSubscribe)
        {
          this.timedOutTimerSubscribe.unsubscribe();
        } 
    
    this._mbService.logout();
  }

  setHeaderAndFooter() {
      this.headerImageDefault = this._mbService.defaultUserProfile.InheritableProperties.HRHeaderLogo.Value;
      this.footerImageDefault = this._mbService.defaultUserProfile.InheritableProperties.HRFooterLogo.Value;
      this.footerAddressDefault = this._mbService.defaultUserProfile.InheritableProperties.HRFooterAddress.Value;
      this.footerPhoneDefault = this._mbService.defaultUserProfile.InheritableProperties.HRFooterPhone.Value;
      var userProfile = this._mbService.userProfile;
      var hasParent = !!this._mbService.parentUserId;
    let userProperties = userProfile.InheritableProperties;
    if (userProperties.HRFooterAddress.Value == "100 Winners Circle, Suite 300, Brentwood, TN 37027") {
      userProperties.HRFooterAddress.Value = this.footerAddressDefault;
    }
      if (hasParent) {
          let parentProperties = this._mbService.parentUserProfile.InheritableProperties;
          this.headerImage = (parentProperties.HRHeaderLogo && parentProperties.HRHeaderLogo.TakeChild == "false") ?
              (parentProperties.HRHeaderLogo.Value || this.headerImageDefault) :
              ((userProperties.HRHeaderLogo) ? userProperties.HRHeaderLogo.Value || this.headerImageDefault : this.headerImageDefault);
          this.footerImage = (parentProperties.HRFooterLogo && parentProperties.HRFooterLogo.TakeChild == "false") ? (parentProperties.HRFooterLogo.Value || this.footerImageDefault) :
              ((userProperties.HRFooterLogo) ? userProperties.HRFooterLogo.Value || this.footerImageDefault : this.footerImageDefault);
          this.footerAddress = (parentProperties.HRFooterAddress && parentProperties.HRFooterAddress.TakeChild == "false") ?
              (parentProperties.HRFooterAddress.Value || this.footerAddressDefault) :
              ((userProperties.HRFooterAddress) ? userProperties.HRFooterAddress.Value || this.footerAddressDefault : this.footerAddressDefault);
          this.footerPhone = (parentProperties.HRFooterPhone && parentProperties.HRFooterPhone.TakeChild == "false") ? (parentProperties.HRFooterPhone.Value || this.footerPhoneDefault) :
              ((userProperties.HRFooterPhone) ? userProperties.HRFooterPhone.Value || this.footerPhoneDefault : this.footerPhoneDefault);
      }
      else {
          this.headerImage = (userProperties.HRHeaderLogo && userProperties.HRHeaderLogo.TakeChild == "false") ? (userProperties.HRHeaderLogo.Value || this.headerImageDefault) :
              this.headerImageDefault;
          this.footerImage = (userProperties.HRFooterLogo && userProperties.HRFooterLogo.TakeChild == "false") ? (userProperties.HRFooterLogo.Value || this.footerImageDefault) :
              this.footerImageDefault;
          this.footerAddress = (userProperties.HRFooterAddress && userProperties.HRFooterAddress.TakeChild == "false") ?
              (userProperties.HRFooterAddress.Value || this.footerAddressDefault) : this.footerAddressDefault;
          this.footerPhone = (userProperties.HRFooterPhone && userProperties.HRFooterPhone.TakeChild == "false") ? (userProperties.HRFooterPhone.Value || this.footerPhoneDefault) :
              this.footerPhoneDefault;
    }
    if (this.headerImage.startsWith('//')) {
      this.headerImage=this.headerImage.replace("//", "/");
    }
    if (this.footerImage.startsWith('//')) {
      this.footerImage = this.footerImage.replace("//", "/");
    }
  }

  showWarningTimeout():void{
    this.showNotificationBanner = true;
    this.warningTimeRemaining=this.warningTime;
    const timedOutTimer  =  timer(0,1000).pipe(
      take(this.warningTime),
      map(() => --this.warningTimeRemaining)
    );
    this.timedOutTimerSubscribe  = timedOutTimer.subscribe(val =>{if(this.warningTimeRemaining<=0){this.logout();}});
    
  }
  helpVisibility()
  {
    return false;
  }

  stay():void{   
    this.showNotificationBanner = false;
    let _ths = this; 

      this._mbService.autoLogin().subscribe((account: Account) => {

        //authentication failed
        if (account == null || account.Authorized == false) {
          this._router.navigate(['login']);
          return false;
        }

        _ths._mbService.setUpAccount(account);

        return true;

      });
      

  }
}


declare global {
  interface Window {
    loggedInUserEmail: string; // or the appropriate type
  }
}
export { };


<style>
  #loginForm {
    margin: 0 auto;
    text-align: center;
  }
</style>

<div class="container" >
  <div class="row">
    <div class="col-md-8 col-md-offset-2">

      <div id="loginForm" class="login-form">

        <div style="text-align:center;">
          <h1>Login</h1>
          <h4>Please login to access {{copy}}</h4>
        </div>

        <input (keyup.enter)="login(userName.value, password.value)" id='username' type="text" placeholder="Username" [value]="userNameValue" #userName class="form-control" /><br />
        <input (keyup.enter)="login(userName.value, password.value)" id= 'password' type="password" placeholder="Password" [value]="passwordValue" #password class="form-control" />

      </div>

      <br />

      <div style="text-align:center;" id="infoBox">

        <input class="btn btn-default" type="button" (click)="login(userName.value, password.value)" value="Login" style="width:100px;margin-bottom: 10px;" id="btnSubmit" />
        <br />
        <div class="error" style="color:red;" [innerHtml] = loginResponse></div>
        <br />
        <b>Unable to log in?</b>
        <br />
        Click <a [href]="forgotLink" target="_blank">here</a> to reset your password or unlock your account.
        <br /><br />
        <b>Forgot your username?</b>
        <br />
        <span>Contact customer care at <a href="mailto:service@blr.com">service@blr.com</a> or call 800-727-5257 between 8 a.m. &ndash; 5 p.m. CT.</span>
        <br /><br />
        <b>Not a subscriber?</b>
        <br />
        Visit <a href="https://hrhero.blr.com/" target="_blank">hrhero.blr.com</a> to learn more.
        <br /><br />

      </div>

      <div style="text-align:center; visibility:hidden;" class="imgloader">

        <h1>Loading</h1>

        <span ><img style="margin-left:5px; height:60px;width:60px;" src="../../assets/images/loading-dots.gif" /></span>

      </div>
      
    </div>
  </div>
</div>

<!--<a (click)="iframeClick()">login iframe test</a>

<iframe id="myiFrame" data-src="http://my.url" src="about:blank" (onLoad)="logIframeEvent(this.contentWindow.location);"></iframe>-->
